
import { useQuery } from "@tanstack/react-query";
import { notificationApi, FeedResponse } from "@/api/notification";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Bell } from "lucide-react";
import { format, differenceInHours } from "date-fns";
import { Badge } from "@/components/ui/badge";
import { useNavigate } from "react-router-dom";

interface FeedListProps {
  userId: number;
}

export const FeedList = ({ userId }: FeedListProps) => {
  const navigate = useNavigate();
  const { data: feeds, isLoading, error } = useQuery({
    queryKey: ['feeds', userId],
    queryFn: () => notificationApi.getFeeds(userId),
    enabled: !!userId,
  });

  const handleFeedClick = (feed: FeedResponse) => {
    if (feed.url) {
      const url = new URL(feed.url);
      const path = url.pathname;
      navigate(path);
    }
  };

  if (isLoading) {
    return (
      <Card className="bg-gradient-to-br from-[#F1F0FB] to-white shadow-lg animate-fade-in">
        <CardHeader>
          <CardTitle className="text-[#221F26] text-xl">Activity Feed</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-center py-4 text-gray-500">Loading...</div>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="bg-gradient-to-br from-[#F1F0FB] to-white shadow-lg">
        <CardHeader>
          <CardTitle className="text-[#221F26] text-xl">Activity Feed</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-center py-4 text-red-500">Failed to load feed</div>
        </CardContent>
      </Card>
    );
  }

  const sortedFeeds = feeds ? [...feeds].sort((a, b) =>
    new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
  ) : [];

  return (
    <Card className="bg-gradient-to-br from-[#F1F0FB] to-white shadow-lg animate-fade-in">
      <CardHeader>
        <CardTitle className="text-[#221F26] text-xl">Activity Feed</CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[400px] pr-4">
          {sortedFeeds.length > 0 ? (
            <div className="space-y-4">
              {sortedFeeds.map((feed: FeedResponse) => (
                <div
                  key={feed.id}
                  className="flex items-start gap-4 p-4 rounded-lg bg-white/50 backdrop-blur-sm transition-all hover:bg-white/80 cursor-pointer"
                  onClick={() => handleFeedClick(feed)}
                >
                  <div className="p-3 rounded-full bg-[#9b87f5]/10">
                    <Bell className="h-4 w-4 text-[#9b87f5]" />
                  </div>
                  <div className="flex-1">
                    <div className="flex items-center gap-2">
                      <p
                        className="text-sm text-[#221F26]"
                        dangerouslySetInnerHTML={{ __html: feed.content }}
                      />
                      {differenceInHours(new Date(), new Date(feed.updated_at)) < 2 && (
                        <Badge
                          variant="default"
                          className="text-xs bg-[#9b87f5] hover:bg-[#8b7bd5]"
                        >
                          New
                        </Badge>
                      )}
                    </div>
                    <p className="text-xs text-gray-400 mt-1">
                      {format(new Date(feed.updated_at), 'MMM d, yyyy HH:mm')}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-4 text-gray-500">
              No new activities
            </div>
          )}
        </ScrollArea>
      </CardContent>
    </Card>
  );
};
