import { config } from '../config';
import { getBaseHeaders } from '../auth/utils';

export const blocksApi = {
  blockUser: async (userId: number, blockedUserId: number): Promise<void> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/block/${blockedUserId}`, {
      method: 'POST',
      headers: getBaseHeaders(),
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Failed to block user');
    }
  },

  unblockUser: async (userId: number, blockedUserId: number): Promise<void> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/block/${blockedUserId}`, {
      method: 'DELETE',
      headers: getBaseHeaders(),
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Failed to unblock user');
    }
  },

  getBlocks: async (userId: number): Promise<any[]> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/blocks`, {
      headers: getBaseHeaders(),
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Failed to fetch blocked users');
    }

    return response.json();
  },
};