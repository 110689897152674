export const normalizeEmail = (email: string): string => {
  if (!email) return '';

  const atIndex = email.indexOf('@');
  if (atIndex === -1) return email.toLowerCase();

  const localPart = email.slice(0, atIndex).toLowerCase();
  const domainPart = email.slice(atIndex);

  return localPart + domainPart;
};
