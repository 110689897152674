import { config } from "./config";

export interface NotificationResponse {
  id: number;
  content: string;
  url: string;
  updated_at: string;
  is_read: boolean;
}

export interface FeedResponse {
  id: number;
  content: string;
  url: string;
  updated_at: string;
}

export interface NotificationSettings {
  settings: {
    [key: string]: {
      notifications: boolean;
      feed: boolean;
      email: boolean;
    };
  };
}

class WebSocketClient {
  private ws: WebSocket | null = null;
  private messageHandlers: ((data: NotificationResponse) => void)[] = [];

  connect(userId: number) {
    return;
  }

  disconnect() {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
  }

  onMessage(handler: (data: NotificationResponse) => void) {
    this.messageHandlers.push(handler);
    return () => {
      this.messageHandlers = this.messageHandlers.filter(h => h !== handler);
    };
  }
}

const wsClient = new WebSocketClient();

export const notificationApi = {
  connectWebSocket: (userId: number) => {
    wsClient.connect(userId);
    return wsClient;
  },

  disconnectWebSocket: () => {
    wsClient.disconnect();
  },

  onNotification: (handler: (data: NotificationResponse) => void) => {
    return wsClient.onMessage(handler);
  },

  getNotifications: async (userId: number): Promise<NotificationResponse[]> => {
    const response = await fetch(
      `${config.API_URL}/notification/${userId}/notifications`,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'x-api-token': config.API_SECRET_TOKEN,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch notifications');
    }

    return response.json();
  },

  getFeeds: async (userId: number): Promise<FeedResponse[]> => {
    const response = await fetch(
      `${config.API_URL}/notification/${userId}/feeds`,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'x-api-token': config.API_SECRET_TOKEN,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch feeds');
    }

    return response.json();
  },

  markAsRead: async (userId: number, notificationId: number): Promise<void> => {
    const response = await fetch(
      `${config.API_URL}/notification/${userId}/notifications/${notificationId}/mark-read`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'x-api-token': config.API_SECRET_TOKEN,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to mark notification as read');
    }
  },

  markAllAsRead: async (userId: number): Promise<void> => {
    const response = await fetch(
      `${config.API_URL}/notification/${userId}/notifications/mark-all-read`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'x-api-token': config.API_SECRET_TOKEN,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to mark all notifications as read');
    }
  },

  getSettings: async (userId: number): Promise<NotificationSettings> => {
    const response = await fetch(
      `${config.API_URL}/notification/${userId}/notifications/settings`,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'x-api-token': config.API_SECRET_TOKEN,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch notification settings');
    }

    return response.json();
  },

  updateSettings: async (userId: number, settings: NotificationSettings): Promise<void> => {
    const response = await fetch(
      `${config.API_URL}/notification/${userId}/notifications/settings`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'x-api-token': config.API_SECRET_TOKEN,
        },
        body: JSON.stringify(settings),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to update notification settings');
    }
  },
};
