import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { userApi } from "@/api/user";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Card } from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";
import { Palette, Trophy, Music, Film, BookOpen, Tv, PaintBucket, Pencil, Circle } from "lucide-react";
import { Interest, InterestCategory } from "@/api/user/interests";

interface InterestsListProps {
  userId: number;
}

const categoryIcons = {
  "hobbies": Palette,
  "sport": Trophy,
  "art": PaintBucket,
  "music": Music,
  "movie": Film,
  "book": BookOpen,
  "tv_show": Tv,
  "other": Circle
};

const categoryDisplayNames = {
  "hobbies": "Hobbies",
  "sport": "Sport",
  "art": "Art",
  "music": "Music",
  "movie": "Movie",
  "book": "Book",
  "tv_show": "TV Show",
  "other": "Other"
};

export const InterestsList = ({ userId }: InterestsListProps) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [category, setCategory] = useState<InterestCategory>("hobbies");
  const [detail, setDetail] = useState("");
  const [editingInterest, setEditingInterest] = useState<Interest | null>(null);

  const { data: interests = [] } = useQuery({
    queryKey: ['interests', userId],
    queryFn: () => userApi.getInterests(userId)
  });

  const createMutation = useMutation({
    mutationFn: (data: { category: InterestCategory; detail: string }) => 
      userApi.createInterest(userId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['interests', userId] });
      toast({ title: "Interest added successfully" });
      setIsOpen(false);
      setDetail("");
    },
    onError: () => {
      toast({ 
        title: "Failed to add interest",
        variant: "destructive"
      });
    }
  });

  const updateMutation = useMutation({
    mutationFn: ({ interestId, data }: { interestId: number; data: { category: InterestCategory; detail: string } }) => 
      userApi.updateInterest(userId, interestId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['interests', userId] });
      toast({ title: "Interest updated successfully" });
      setIsEditOpen(false);
      setEditingInterest(null);
    },
    onError: () => {
      toast({ 
        title: "Failed to update interest",
        variant: "destructive"
      });
    }
  });

  const deleteMutation = useMutation({
    mutationFn: (interestId: number) => userApi.deleteInterest(userId, interestId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['interests', userId] });
      toast({ title: "Interest removed successfully" });
    },
    onError: () => {
      toast({ 
        title: "Failed to remove interest",
        variant: "destructive"
      });
    }
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!detail.trim()) return;
    createMutation.mutate({ category, detail });
  };

  const handleEditSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!editingInterest || !detail.trim()) return;
    updateMutation.mutate({
      interestId: editingInterest.id,
      data: { category, detail }
    });
  };

  const openEditDialog = (interest: Interest) => {
    setEditingInterest(interest);
    setCategory(interest.category);
    setDetail(interest.detail);
    setIsEditOpen(true);
  };

  return (
    <Card className="p-6 bg-gradient-to-br from-[#F1F0FB] to-white shadow-lg animate-fade-in border border-[#9b87f5]/20">
      <div className="flex items-center justify-between mb-6">
        <h2 className="font-semibold text-[#1A1F2C] text-xl">Interests</h2>
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
          <DialogTrigger asChild>
            <Button variant="outline" className="gap-2">
              Add Interest
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add New Interest</DialogTitle>
            </DialogHeader>
            <form onSubmit={handleSubmit} className="space-y-4">
              <Select value={category} onValueChange={(value) => setCategory(value as InterestCategory)}>
                <SelectTrigger>
                  <SelectValue placeholder="Select category" />
                </SelectTrigger>
                <SelectContent>
                  {Object.entries(categoryDisplayNames).map(([value, label]) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Input
                placeholder="Enter your interest..."
                value={detail}
                onChange={(e) => setDetail(e.target.value)}
              />
              <Button type="submit" className="w-full">Add Interest</Button>
            </form>
          </DialogContent>
        </Dialog>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {interests.map((interest: Interest) => {
          const IconComponent = categoryIcons[interest.category];
          return (
            <div
              key={interest.id}
              className="flex items-center gap-4 p-4 rounded-lg bg-white/50 backdrop-blur-sm transition-all hover:bg-white/80"
            >
              <div className="p-3 rounded-full bg-[#9b87f5]/10">
                <IconComponent className="h-6 w-6 text-[#7E69AB]" />
              </div>
              <div className="flex-1">
                <p className="text-sm text-[#8E9196]">{categoryDisplayNames[interest.category]}</p>
                <h3 className="font-semibold text-[#1A1F2C]">{interest.detail}</h3>
              </div>
              <div className="flex gap-2">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => openEditDialog(interest)}
                  className="text-gray-400 hover:text-[#7E69AB]"
                >
                  <Pencil className="h-4 w-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => deleteMutation.mutate(interest.id)}
                  className="text-gray-400 hover:text-red-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="h-4 w-4"
                  >
                    <path d="M18 6 6 18" />
                    <path d="m6 6 12 12" />
                  </svg>
                </Button>
              </div>
            </div>
          );
        })}
      </div>

      {/* Edit Dialog */}
      <Dialog open={isEditOpen} onOpenChange={setIsEditOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Interest</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleEditSubmit} className="space-y-4">
            <Select value={category} onValueChange={(value) => setCategory(value as InterestCategory)}>
              <SelectTrigger>
                <SelectValue placeholder="Select category" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(categoryDisplayNames).map(([value, label]) => (
                  <SelectItem key={value} value={value}>
                    {label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Input
              placeholder="Enter your interest..."
              value={detail}
              onChange={(e) => setDetail(e.target.value)}
            />
            <Button type="submit" className="w-full">Update Interest</Button>
          </form>
        </DialogContent>
      </Dialog>
    </Card>
  );
};
