import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";

type Language = "en" | "de";

interface LanguageContextType {
  language: Language;
  setLanguage: (lang: Language) => void;
  translate: (key: string) => string;
}

const translations = {
  en: {
    "home.hero.title": "Create Magical Gift Moments",
    "home.hero.subtitle": "Share your wishes, discover perfect gifts, and make every occasion special",
    "button.signup": "Sign Up",
    "button.signin": "Sign In",
    "language.switch.to": "Switch to German",
    "home.cta.title": "Ready to Make Gift-Giving Magical?",
    "home.cta.subtitle": "Join and start creating your wishlists today",
    "home.feature.wishlist.title": "Smart Wishlists",
    "home.feature.wishlist.description": "Create and share personalized wishlists with friends and family",
    "home.feature.ai.title": "AI Gift Assistant",
    "home.feature.ai.description": "Get personalized gift recommendations powered by AI",
    "home.feature.social.title": "Social Gifting",
    "home.feature.social.description": "Connect with friends and never miss important occasions",
    "home.feature.wanted.title": "Give what's wanted",
    "home.feature.wanted.description": "Ensure your gifts are always appreciated",
    "home.feature.price.title": "Price Drop Alerts",
    "home.feature.price.description": "Get notified when prices fall on your wishlist items and your friend wishlist",
    "home.feature.recommendation.title": "Smart Recommendation",
    "home.feature.recommendation.description": "Discover gifts tailored to each person's unique taste",
    "home.section.why": "Why People Love Our Platform",
    "nav.about": "About",
    "nav.contact": "Contact",
    "nav.faq": "FAQ",
    "nav.privacy": "Privacy Policy",
    "nav.terms": "Terms of Service",
    "nav.impressum": "Impressum",
    "login.welcome": "Welcome Back",
    "login.continue.email": "Or continue with email",
    "login.email": "Email",
    "login.password": "Password",
    "login.forgot": "Forgot password?",
    "login.no.account": "Don't have an account?",
    "login.register": "Register",
    "register.create": "Create Account",
    "register.or.email": "Or register with email",
    "register.have.account": "Already have an account?",
    "forgot.title": "Forgot Password?",
    "forgot.description": "Enter your email and we'll send you a password reset link",
    "forgot.email.placeholder": "Enter your email",
    "forgot.sending": "Sending...",
    "forgot.send": "Send Reset Link",
    "forgot.back": "Back to Login"
  },
  de: {
    "home.hero.title": "Magische Geschenkmomente kreieren",
    "home.hero.subtitle": "Teile deine Wünsche, entdecke perfekte Geschenke und mache jede Gelegenheit besonders",
    "button.signup": "Registrieren",
    "button.signin": "Anmelden",
    "language.switch.to": "Wechseln zu Englisch",
    "home.cta.title": "Bereit, das Schenken magisch zu machen?",
    "home.cta.subtitle": "Tritt bei und erstelle noch heute deine Wunschlisten",
    "home.feature.wishlist.title": "Intelligente Wunschlisten",
    "home.feature.wishlist.description": "Erstelle und teile personalisierte Wunschlisten mit Freunden und Familie",
    "home.feature.ai.title": "KI-Geschenkassistent",
    "home.feature.ai.description": "Erhalte personalisierte Geschenkempfehlungen mit KI-Unterstützung",
    "home.feature.social.title": "Soziales Schenken",
    "home.feature.social.description": "Verbinde dich mit Freunden und verpasse keine wichtigen Anlässe mehr",
    "home.feature.wanted.title": "Schenke was gewünscht wird",
    "home.feature.wanted.description": "Stelle sicher, dass deine Geschenke immer geschätzt werden",
    "home.feature.price.title": "Preissenkungsalarme",
    "home.feature.price.description": "Werde benachrichtigt, wenn die Preise für deine Wunschlistenartikel fallen",
    "home.feature.recommendation.title": "Intelligente Empfehlungen",
    "home.feature.recommendation.description": "Entdecke Geschenke, die auf den einzigartigen Geschmack jeder Person zugeschnitten sind",
    "home.section.why": "Warum Menschen unsere Plattform lieben",
    "nav.about": "Über uns",
    "nav.contact": "Kontakt",
    "nav.faq": "FAQ",
    "nav.privacy": "Datenschutz",
    "nav.terms": "Nutzungsbedingungen",
    "nav.impressum": "Impressum",
    "login.welcome": "Willkommen zurück",
    "login.continue.email": "Oder weiter mit E-Mail",
    "login.email": "E-Mail",
    "login.password": "Passwort",
    "login.forgot": "Passwort vergessen?",
    "login.no.account": "Noch kein Konto?",
    "login.register": "Registrieren",
    "register.create": "Konto erstellen",
    "register.or.email": "Oder registriere dich mit E-Mail",
    "register.have.account": "Hast du bereits ein Konto?",
    "forgot.title": "Passwort vergessen?",
    "forgot.description": "Gib deine E-Mail-Adresse ein und wir senden dir einen Link zum Zurücksetzen des Passworts",
    "forgot.email.placeholder": "Gib deine E-Mail ein",
    "forgot.sending": "Wird gesendet...",
    "forgot.send": "Link zum Zurücksetzen senden",
    "forgot.back": "Zurück zur Anmeldung"
  }
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState<Language>(() => {
    const savedLanguage = localStorage.getItem("language") as Language;
    if (savedLanguage && (savedLanguage === "en" || savedLanguage === "de")) {
      return savedLanguage;
    }
    const browserLang = navigator.language.substring(0, 2);
    return browserLang === "de" ? "de" : "en";
  });

  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  const translate = (key: string): string => {
    return translations[language][key as keyof typeof translations[typeof language]] || key;
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, translate }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
