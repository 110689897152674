import { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { User, RegisterData } from "../types/auth";
import { authApi } from "../api/auth";
import { userApi } from "../api/user";
import { Skeleton } from "@/components/ui/skeleton";
import { normalizeEmail } from "@/utils/emailUtils";

interface AuthContextType {
  user: User | null;
  isLoading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (userData: RegisterData) => Promise<void>;
  updateUserField: (userId: number, field: string, value: any) => Promise<void>;
  deactivateAccount: (userId: number, password: string) => Promise<void>;
  deleteAccount: (userId: number, password: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    let lastActivityTime = Date.now();

    const checkAuth = async (force: boolean = false) => {
      try {
        const userData = await authApi.validate(force);
        if (userData && isMounted) {
          setUser(userData as User);
        }
      } catch (error) {
        if (isMounted) {
          setUser(null);
          if (error instanceof Error && error.message === 'SESSION_EXPIRED') {
            navigate('/login');
          }
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    const handleActivity = () => {
      const now = Date.now();
      if (now - lastActivityTime > 5 * 60 * 1000) {
        checkAuth(true);
      }
      lastActivityTime = now;
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('click', handleActivity);

    checkAuth(true);

    const intervalId = setInterval(() => {
      const now = Date.now();
      if (now - lastActivityTime < 5 * 60 * 1000) {
        checkAuth();
      }
    }, 5 * 60 * 1000);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('click', handleActivity);
    };
  }, [navigate]);

  const login = async (email: string, password: string) => {
    try {
      const normalizedEmail = normalizeEmail(email);
      const userData = await authApi.login({ email: normalizedEmail, password });
      setUser(userData as User);
      navigate('/');
    } catch (error) {
      throw error;
    }
  };

  const logout = async () => {
    try {
      await authApi.logout();
      setUser(null);
      navigate('/', { replace: true });
    } catch (error) {
      throw error;
    }
  };

  const register = async (userData: RegisterData) => {
    try {
      const normalizedUserData = {
        ...userData,
        email: normalizeEmail(userData.email),
      };
      await authApi.register(normalizedUserData);
      navigate('/login');
    } catch (error) {
      throw error;
    }
  };

  const updateUserField = async (userId: number, field: string, value: any) => {
    try {
      if (field === 'email') {
        value = normalizeEmail(value);
      }
      const updatedUser = await userApi.updateField(userId, field, value);
      setUser(updatedUser as User);
    } catch (error) {
      throw error;
    }
  };

  const deactivateAccount = async (userId: number, password: string) => {
    try {
      await userApi.deactivate(userId, { password });
      setUser(null);
      navigate('/login');
    } catch (error) {
      throw error;
    }
  };

  const deleteAccount = async (userId: number, password: string) => {
    try {
      await userApi.delete(userId, { password });
      setUser(null);
      navigate('/login');
    } catch (error) {
      throw error;
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-[#F1F0FB] to-[#E5DEFF] flex items-center justify-center">
        <div className="w-full max-w-md space-y-4 p-6">
          <Skeleton className="h-12 w-3/4 mb-4" />
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-2/3" />
          <Skeleton className="h-10 w-full mt-6" />
        </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{
      user,
      isLoading,
      login,
      logout,
      register,
      updateUserField,
      deactivateAccount,
      deleteAccount
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }
  return context;
};
