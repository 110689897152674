import { RegisterData } from "@/types/auth";
import { config } from "../config";
import { getBaseHeaders, handleApiError } from "./utils";
import { normalizeEmail } from "@/utils/emailUtils";

export const register = async (userData: RegisterData) => {
  try {
    const normalizedUserData = {
      ...userData,
      email: normalizeEmail(userData.email),
    };

    const response = await fetch(`${config.API_URL}/user/register`, {
      method: 'POST',
      headers: getBaseHeaders(),
      body: JSON.stringify(normalizedUserData),
      credentials: 'include',
    });

    if (!response.ok) {
      await handleApiError(response, 'Registration');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
