import { login } from "./login";
import { register } from "./register";
import { validate } from "./validation";
import { logout } from "./logout";
import { getGoogleSSOLoginUrl } from "./sso";
import { config } from "../config";
import { normalizeEmail } from "@/utils/emailUtils";

const forgotPassword = async (email: string) => {
  const normalizedEmail = normalizeEmail(email);

  const response = await fetch(`${config.API_URL}/user/forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-api-token': config.API_SECRET_TOKEN,
    },
    body: JSON.stringify({ email: normalizedEmail }),
  });

  if (!response.ok) {
    throw new Error('Failed to send password reset email');
  }

  return response.json();
};

const verifyEmail = async (token: string) => {
  const response = await fetch(`${config.API_URL}/user/verify-email/${token}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-api-token': config.API_SECRET_TOKEN,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to verify email');
  }

  return response.json();
};

const resetPassword = async (token: string, password: string) => {
  const response = await fetch(`${config.API_URL}/user/reset-password/${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-api-token': config.API_SECRET_TOKEN,
    },
    body: JSON.stringify({ password }),
  });

  if (!response.ok) {
    throw new Error('Failed to reset password');
  }

  return response.json();
};

export const authApi = {
  login,
  register,
  validate,
  logout,
  getGoogleSSOLoginUrl,
  forgotPassword,
  verifyEmail,
  resetPassword,
};
