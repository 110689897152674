import { Bell, Check } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/hooks/useAuth";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { notificationApi, NotificationResponse } from "@/api/notification";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { useNavigate } from "react-router-dom";

export const NotificationsMenu = () => {
  const { user } = useAuth();
  const { toast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [notifications, setNotifications] = useState<NotificationResponse[]>([]);

  const { data: initialNotifications } = useQuery({
    queryKey: ['notifications', user?.id],
    queryFn: () => user?.id ? notificationApi.getNotifications(user.id) : Promise.resolve([]),
    enabled: !!user?.id,
  });

  const markAsReadMutation = useMutation({
    mutationFn: ({ userId, notificationId }: { userId: number; notificationId: number }) =>
      notificationApi.markAsRead(userId, notificationId),
    onSuccess: (_, variables) => {
      setNotifications(prev =>
        prev.map(notification =>
          notification.id === variables.notificationId
            ? { ...notification, is_read: true }
            : notification
        )
      );
      queryClient.invalidateQueries({ queryKey: ['notifications', user?.id] });
      toast({
        title: "Success",
        description: "Notification marked as read",
      });
    },
  });

  const markAllAsReadMutation = useMutation({
    mutationFn: (userId: number) => notificationApi.markAllAsRead(userId),
    onSuccess: () => {
      setNotifications(prev => prev.map(notification => ({ ...notification, is_read: true })));
      queryClient.invalidateQueries({ queryKey: ['notifications', user?.id] });
      toast({
        title: "Success",
        description: "All notifications marked as read",
      });
    },
  });

  useEffect(() => {
    if (initialNotifications) {
      setNotifications(initialNotifications);
    }
  }, [initialNotifications]);

  useEffect(() => {
    if (!user?.id) return;

    notificationApi.connectWebSocket(user.id);

    const unsubscribe = notificationApi.onNotification((newNotification) => {
      setNotifications(prev => [newNotification, ...prev]);
    });

    return () => {
      unsubscribe();
      notificationApi.disconnectWebSocket();
    };
  }, [user?.id]);

  const handleMarkAsRead = (notificationId: number) => {
    if (!user?.id) return;
    markAsReadMutation.mutate({ userId: user.id, notificationId });
  };

  const handleMarkAllAsRead = () => {
    if (!user?.id) return;
    markAllAsReadMutation.mutate(user.id);
  };

  const handleNotificationClick = (notification: NotificationResponse) => {
    if (notification.url) {
      const url = new URL(notification.url);
      const path = url.pathname;
      navigate(path);
    }

    if (!notification.is_read && user?.id) {
      handleMarkAsRead(notification.id);
    }
  };

  const unreadCount = notifications.filter(n => !n.is_read).length;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="relative">
          <Bell className="h-5 w-5" />
          {unreadCount > 0 && (
            <span className="absolute -top-1 -right-1 h-4 w-4 rounded-full bg-red-500 text-[10px] font-medium text-white flex items-center justify-center">
              {unreadCount}
            </span>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-80" align="end">
        <div className="flex items-center justify-between px-2">
          <DropdownMenuLabel>Notifications</DropdownMenuLabel>
          {unreadCount > 0 && (
            <Button
              variant="ghost"
              size="sm"
              className="text-xs flex items-center gap-1 text-muted-foreground hover:text-primary"
              onClick={handleMarkAllAsRead}
            >
              <Check className="h-3 w-3" />
              Mark all as read
            </Button>
          )}
        </div>
        <DropdownMenuSeparator />
        <DropdownMenuGroup className="max-h-[400px] overflow-y-auto">
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <DropdownMenuItem
                key={notification.id}
                className={`cursor-pointer flex flex-col items-start gap-1 py-2 ${
                  !notification.is_read
                    ? 'bg-blue-50 dark:bg-blue-900/20 border-l-4 border-blue-500 font-semibold'
                    : 'opacity-75'
                }`}
                onClick={() => handleNotificationClick(notification)}
              >
                <p
                  className="text-sm"
                  dangerouslySetInnerHTML={{ __html: notification.content }}
                />
                <span className="text-xs text-gray-500">
                  {format(new Date(notification.updated_at), 'MMM d, yyyy HH:mm')}
                </span>
                {!notification.is_read && (
                  <span className="text-xs text-blue-600 dark:text-blue-400">
                    New
                  </span>
                )}
              </DropdownMenuItem>
            ))
          ) : (
            <DropdownMenuItem disabled>
              No new notifications
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
