import { Home, Heart, Users, Settings, Bot, Lightbulb, Info, Mail, HelpCircle, FileText, Shield, BookOpen } from "lucide-react";
import { cn } from "@/lib/utils";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useLanguage } from "@/contexts/LanguageContext";
import { ScrollArea } from "@/components/ui/scroll-area";

export const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { translate } = useLanguage();

  const menuItems = [
    { icon: Home, label: "Home", href: "/" },
    { icon: Users, label: "Friends", href: "/friends" },
    { icon: Heart, label: "Wishlist", href: "/wishlist" },
    { icon: Lightbulb, label: "Recommendations", href: "/recommendations" },
    { icon: Bot, label: "AI Assistant", href: "/ai-assistant" },
    { icon: Settings, label: "Settings", href: "/settings" },
  ];

  const commonMenuItems = [
    { icon: Info, label: "About", href: "/about" },
    { icon: Mail, label: "Contact", href: "/contact" },
    { icon: HelpCircle, label: "FAQ", href: "/faq" },
    { icon: Shield, label: "Privacy Policy", href: "/privacy-policy" },
    { icon: FileText, label: "Terms of Service", href: "/terms-of-service" },
    { icon: BookOpen, label: "Impressum", href: "/impressum" },
  ];

  const handleNavigation = (href: string) => {
    if (location.pathname === href) {
      navigate(0);
    } else {
      navigate(href);
    }
  };

  return (
    <aside
      className={cn(
        "fixed left-0 top-0 h-full w-64 bg-gradient-to-b from-white to-[#F1F0FB] border-r border-gray-200 transform lg:translate-x-0 z-40",
        "lg:top-16 lg:h-[calc(100vh-4rem)]",
        "hidden lg:block animate-fade-in"
      )}
    >
      <div className="flex flex-col h-full">
        <div className="h-16 lg:h-0" />
        <ScrollArea className="flex-1 py-6">
          <div className="flex flex-col">
            <ul className="space-y-1 px-3">
              {menuItems.map((item) => (
                <li key={item.label}>
                  <button
                    onClick={() => handleNavigation(item.href)}
                    className="w-full flex items-center gap-3 px-4 py-3 text-gray-700 rounded-lg hover:bg-white/50 hover:text-[#9b87f5] transition-all duration-200 group"
                  >
                    <div className="p-2 rounded-lg bg-white/80 shadow-sm group-hover:shadow-md transition-shadow">
                      <item.icon className="h-5 w-5" />
                    </div>
                    <span className="font-medium">{item.label}</span>
                  </button>
                </li>
              ))}
            </ul>

            <div className="mt-6 pt-6 border-t border-gray-200">
              <h3 className="px-6 mb-2 text-xs font-semibold text-gray-500 uppercase">Pages</h3>
              <ul className="space-y-1 px-3">
                {commonMenuItems.map((item) => (
                  <li key={item.label}>
                    <button
                      onClick={() => handleNavigation(item.href)}
                      className="w-full flex items-center gap-3 px-4 py-2 text-gray-600 rounded-lg hover:bg-white/50 hover:text-[#9b87f5] transition-all duration-200 group"
                    >
                      <item.icon className="h-4 w-4" />
                      <span className="text-sm">{item.label}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </ScrollArea>
      </div>
    </aside>
  );
};
