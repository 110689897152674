import { config } from '../config';
import { User } from '../../types/api';
import { getBaseHeaders } from '../auth/utils';

export const searchApi = {
  searchByName: async (userId: number, query: string): Promise<User[]> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/search?query=${encodeURIComponent(query)}`, {
      headers: getBaseHeaders(),
      credentials: 'include',
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.detail || data.message || 'Failed to search users');
    }

    return data;
  }
};
