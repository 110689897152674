import { config } from '../config';
import { User } from '../../types/api';
import { getBaseHeaders } from '../auth/utils';

interface FriendRequest {
  friend_id: number;
  friend_full_name: string;
}

interface Friend {
  friend_id: number;
  friend_full_name: string;
  nickname?: string;
  birthday?: string | null;
  wishlist?: Array<{
    id: number;
    name: string;
    price?: string;
    isPublic?: boolean;
  }>;
}

type FriendRequestStatus = 'pending' | 'accepted' | 'available';

interface FriendRequestStatusResponse {
  status: FriendRequestStatus;
}

export const friendsApi = {
  getFriendRequestStatus: async (userId: number, receiverId: number): Promise<FriendRequestStatus> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/friend-request/${receiverId}/status`, {
      headers: getBaseHeaders(),
      credentials: 'include',
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.detail || data.message || response.statusText);
    }

    const data: FriendRequestStatusResponse = await response.json();
    return data.status;
  },

  getFriends: async (userId: number): Promise<Friend[]> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/friends`, {
      headers: getBaseHeaders(),
      credentials: 'include',
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.detail || data.message || response.statusText);
    }

    return response.json();
  },

  getFriendProfile: async (userId: number, friendId: number): Promise<Friend> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/friend/${friendId}`, {
      headers: getBaseHeaders(),
      credentials: 'include',
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.detail || data.message || response.statusText);
    }

    const data = await response.json();
    return {
      friend_id: data.friend_id,
      friend_full_name: data.full_name,
      nickname: data.nickname,
      birthday: data.birthday,
      wishlist: data.wishlist
    };
  },

  getPendingFriendRequests: async (userId: number): Promise<FriendRequest[]> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/friend-request/pending`, {
      method: 'GET',
      headers: getBaseHeaders(),
      credentials: 'include',
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.detail || data.message || response.statusText);
    }

    return response.json();
  },

  sendFriendRequest: async (userId: number, friendId: number): Promise<void> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/friend-requests`, {
      method: 'POST',
      headers: getBaseHeaders(),
      credentials: 'include',
      body: JSON.stringify({
        sender_id: userId,
        receiver_id: friendId
      }),
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.detail || data.message || response.statusText);
    }
  },

  cancelFriendRequest: async (userId: number, friendId: number): Promise<void> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/friend-requests/${friendId}`, {
      method: 'DELETE',
      headers: getBaseHeaders(),
      credentials: 'include',
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.detail || data.message || response.statusText);
    }
  },

  respondToFriendRequest: async (userId: number, requestId: number, response: { status: 'accepted' | 'rejected' }): Promise<void> => {
    const apiResponse = await fetch(`${config.API_URL}/user/${userId}/friend-request/${requestId}`, {
      method: 'PATCH',
      headers: getBaseHeaders(),
      credentials: 'include',
      body: JSON.stringify(response),
    });

    if (!apiResponse.ok) {
      const data = await apiResponse.json();
      throw new Error(data.detail || data.message || apiResponse.statusText);
    }
  },

  removeFriend: async (userId: number, friendId: number): Promise<void> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/friend/${friendId}`, {
      method: 'DELETE',
      headers: getBaseHeaders(),
      credentials: 'include',
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.detail || data.message || response.statusText);
    }
  },
};
