import { config } from "../config";

export const getBaseHeaders = () => ({
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'x-api-token': config.API_SECRET_TOKEN,
});

export const handleApiError = async (response: Response, operation: string) => {
  let errorData;
  try {
    const data = await response.json();

    if (Array.isArray(data.detail)) {
      errorData = data.detail[0]?.msg || data.detail[0]?.message;
    } else {
      errorData = data.detail || data.message || data.error || response.statusText;
    }
  } catch (e) {
    errorData = response.statusText;
  }

  if (errorData) {
    throw new Error(errorData);
  }

  throw new Error(`${operation} failed: ${response.statusText}`);
};
