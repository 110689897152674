import { config } from "../config";
import { getBaseHeaders, handleApiError } from "./utils";
import { validate } from "./validation";
import { normalizeEmail } from "@/utils/emailUtils";

const getGeolocation = async (): Promise<{ latitude: number; longitude: number } | null> => {
  if ('geolocation' in navigator) {
    try {
      const position = await Promise.race([
        new Promise<GeolocationPosition>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        }),
        new Promise<null>((resolve) => {
          setTimeout(() => {
            resolve(null);
          }, 10);
        })
      ]);

      if (!position) {
        return null;
      }

      return {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      };
    } catch (error) {
      return null;
    }
  }
  return null;
};

export const login = async (credentials: { email: string; password: string }) => {
  try {
    const location = await getGeolocation();
    const requestBody = {
      ...credentials,
      email: normalizeEmail(credentials.email),
      location: location || undefined
    };

    const response = await fetch(`${config.API_URL}/user/login`, {
      method: 'POST',
      headers: getBaseHeaders(),
      body: JSON.stringify(requestBody),
      credentials: 'include',
    });

    if (!response.ok) {
      await handleApiError(response, 'Login');
    }

    const loginData = await response.json();
    const userData = await validate();
    return userData;
  } catch (error) {
    throw error;
  }
};
