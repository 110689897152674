import { toast } from "sonner";

export { toast };

export function useToast() {
  return {
    toast: (props: { title: string; description?: string; variant?: "default" | "destructive" }) => {
      if (props.variant === "destructive") {
        toast.error(props.title, {
          description: props.description,
          duration: 3000
        });
      } else {
        toast.success(props.title, {
          description: props.description,
          duration: 500
        });
      }
    }
  };
}
