
import { config } from "../config";
import { getBaseHeaders, handleApiError } from "./utils";

let lastValidationTime: number | null = null;
const VALIDATION_COOLDOWN = 4 * 60 * 1000;

export const validate = async (force: boolean = false) => {
  const now = Date.now();

  if (!force && lastValidationTime && (now - lastValidationTime < VALIDATION_COOLDOWN)) {
    return null;
  }

  try {
    const abortController = new AbortController();
    const timeoutId = setTimeout(() => abortController.abort(), 5000);

    const response = await fetch(`${config.API_URL}/user/validate`, {
      method: 'GET',
      headers: getBaseHeaders(),
      credentials: 'include',
      signal: abortController.signal,
    });

    clearTimeout(timeoutId);

    if (!response.ok) {
      if (response.status === 401) {
        throw new Error('SESSION_EXPIRED');
      }
      await handleApiError(response, 'Session validation');
    }

    const data = await response.json();
    lastValidationTime = now;
    return data;
  } catch (error) {
    if (error instanceof Error && error.name === 'AbortError') {
      console.error('Session validation request timed out');
    }
    throw error;
  }
};
