
import { profileApi } from './user/profile';
import { friendsApi } from './user/friends';
import { blocksApi } from './user/blocks';
import { searchApi } from './user/search';
import { interestsApi } from './user/interests';

export const userApi = {
  ...profileApi,
  ...friendsApi,
  ...blocksApi,
  ...searchApi,
  ...interestsApi,
};
