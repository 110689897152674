
import { config } from '../config';
import { User } from '../../types/api';
import { getBaseHeaders } from '../auth/utils';
import { WishlistItemUser } from '../../types/wishlist';

export interface PublicProfileResponse {
  id: number;
  friend_full_name: string;
  nickname?: string;
  birthday?: string;
  wishlist: WishlistItemUser[];
}

export const profileApi = {
  getLocationSettings: async (userId: number): Promise<{ location: boolean }> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/settings/location`, {
      headers: getBaseHeaders(),
      credentials: 'include',
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.detail || data.message || response.statusText);
    }

    return response.json();
  },

  setLocationSettings: async (userId: number, locationEnabled: boolean): Promise<void> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/settings/location`, {
      method: 'POST',
      headers: getBaseHeaders(),
      credentials: 'include',
      body: JSON.stringify({ location_bool: locationEnabled }),
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.detail || data.message || response.statusText);
    }
  },

  updateField: async (userId: number, field: string, value: any): Promise<User> => {
    let requestBody;
    if (field === 'phone_number') {
      requestBody = { phone_number: value };
    } else if (field === 'birthday') {
      requestBody = { birthday: value };
    } else if (field === 'full_name') {
      requestBody = { full_name: value };
    } else if (field === 'nickname') {
      requestBody = { nickname: value };
    } else if (field === 'gender') {
      requestBody = { gender: value };
    } else if (field === 'bio') {
      requestBody = { bio: value };
    } else if (field === 'instagram') {
      requestBody = { instagram: value };
    } else if (field === 'facebook') {
      requestBody = { facebook: value };
    } else if (field === 'password') {
      requestBody = {
        password: value.password,
        new_password: value.new_password
      };
    } else {
      requestBody = { value };
    }

    const response = await fetch(`${config.API_URL}/user/update/${userId}/${field}`, {
      method: 'PATCH',
      headers: getBaseHeaders(),
      credentials: 'include',
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.detail || data.message || response.statusText);
    }

    return response.json();
  },

  deactivate: async (userId: number, data: { password: string }): Promise<void> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/deactivate`, {
      method: 'POST',
      headers: getBaseHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.detail || data.message || response.statusText);
    }
  },

  delete: async (userId: number, data: { password: string }): Promise<void> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/delete`, {
      method: 'DELETE',
      headers: getBaseHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.detail || data.message || response.statusText);
    }
  },

  getPublicProfile: async (userId: number): Promise<PublicProfileResponse> => {
    const userResponse = await fetch(`${config.API_URL}/user/public/${userId}`, {
      headers: getBaseHeaders(),
      credentials: 'include',
    });

    if (!userResponse.ok) {
      const data = await userResponse.json();
      throw new Error(data.detail || data.message || userResponse.statusText);
    }

    const userData = await userResponse.json();
    const wishlistResponse = await fetch(`${config.API_URL}/wishlist/public/${userId}`, {
      headers: getBaseHeaders(),
      credentials: 'include',
    });

    if (!wishlistResponse.ok) {
      const data = await wishlistResponse.json();
      throw new Error(data.detail || data.message || wishlistResponse.statusText);
    }

    const wishlistData = await wishlistResponse.json();
    return {
      id: userId,
      friend_full_name: userData.full_name || 'Unknown',
      nickname: userData.nickname,
      wishlist: wishlistData || []
    };
  },
};
