import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { Settings, ExternalLink, Globe, Cookie, X, Info, Check } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Link } from "react-router-dom";
import { useLanguage } from "@/contexts/LanguageContext";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

type CookieSettings = {
  necessary: boolean;
  functional: boolean;
  analytics: boolean;
  marketing: boolean;
};

type ConsentRecord = {
  settings: CookieSettings;
  timestamp: string;
  userAgent: string;
  method: "banner" | "detailed" | "default";
  version: string;
};

const CONSENT_VERSION = "1.0.0";

const defaultSettings: CookieSettings = {
  necessary: true,
  functional: false,
  analytics: false,
  marketing: false,
};

const saveCookiePreferences = (settings: CookieSettings, method: "banner" | "detailed" | "default") => {
  const consentRecord: ConsentRecord = {
    settings,
    timestamp: new Date().toISOString(),
    userAgent: navigator.userAgent,
    method,
    version: CONSENT_VERSION
  };

  localStorage.setItem('cookieConsent', JSON.stringify(consentRecord));
  applyCookieSettings(settings);
  window.dispatchEvent(new CustomEvent('cookieConsentUpdated', { detail: settings }));
};

const applyCookieSettings = (settings: CookieSettings) => {
  if (!settings.functional) {
    deleteCookiesByCategory('functional');
  }

  if (!settings.analytics) {
    deleteCookiesByCategory('analytics');
    if (window.hasOwnProperty('ga')) {
      // @ts-ignore
      window['ga-disable-UA-XXXXXXXX-Y'] = true;
    }
  }

  if (!settings.marketing) {
    deleteCookiesByCategory('marketing');
  }
};

const deleteCookiesByCategory = (category: string) => {
  const cookiesToDelete: Record<string, string[]> = {
    functional: ['ui-settings', 'language'],
    analytics: ['_ga', '_gid', '_gat'],
    marketing: ['ads_id', 'fbp']
  };

  cookiesToDelete[category]?.forEach(cookieName => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
};

const getCookiePreferences = (): ConsentRecord | null => {
  const saved = localStorage.getItem('cookieConsent');
  return saved ? JSON.parse(saved) : null;
};

const cookieDetails = {
  necessary: [
    { name: "cookieConsent", purpose: "Stores your cookie preferences", duration: "1 year", provider: "BuyForS" },
    { name: "SESSION", purpose: "Maintains your login session", duration: "Session", provider: "BuyForS" },
    { name: "XSRF-TOKEN", purpose: "Prevents cross-site request forgery attacks", duration: "Session", provider: "BuyForS" },
  ],
  functional: [
    { name: "language", purpose: "Remembers your language preference", duration: "1 year", provider: "BuyForS" },
    { name: "ui-settings", purpose: "Stores interface customizations", duration: "1 year", provider: "BuyForS" },
  ],
  analytics: [
    { name: "_ga", purpose: "Distinguishes users for Google Analytics", duration: "2 years", provider: "Google" },
    { name: "_gid", purpose: "Counts and tracks pageviews", duration: "24 hours", provider: "Google" },
  ],
  marketing: [
    { name: "ads_id", purpose: "Used for targeted advertising", duration: "90 days", provider: "BuyForS" },
    { name: "fbp", purpose: "Facebook pixel for advertising effectiveness", duration: "90 days", provider: "Facebook" },
  ],
};

export const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [showDetailedSettings, setShowDetailedSettings] = useState(false);
  const { language, setLanguage } = useLanguage();

  const content = {
    en: {
      title: "We respect your privacy choices",
      description: "We use cookies to enhance your browsing experience, personalize content and ads, provide social media features, and analyze our traffic. Please choose your preferences below:",
      necessaryOnly: "Accept only necessary",
      acceptAll: "Accept all",
      rejectAll: "Reject all",
      customize: "Customize settings",
      privacyPolicy: "Privacy Policy",
      termsOfService: "Terms of Service",
      cookieSettings: "Cookie Settings",
      moreInfo: "More information",
      saveChoices: "Save choices"
    },
    de: {
      title: "Wir respektieren Ihre Datenschutzentscheidungen",
      description: "Wir verwenden Cookies, um Ihr Surferlebnis zu verbessern, Inhalte und Anzeigen zu personalisieren, Social-Media-Funktionen bereitzustellen und unseren Datenverkehr zu analysieren. Bitte wählen Sie unten Ihre Präferenzen:",
      necessaryOnly: "Nur notwendige akzeptieren",
      acceptAll: "Alle akzeptieren",
      rejectAll: "Alle ablehnen",
      customize: "Einstellungen anpassen",
      privacyPolicy: "Datenschutz",
      termsOfService: "Nutzungsbedingungen",
      cookieSettings: "Cookie-Einstellungen",
      moreInfo: "Weitere Informationen",
      saveChoices: "Auswahl speichern"
    }
  };

  const t = content[language];

  useEffect(() => {}, [language]);

  useEffect(() => {
    const savedPreferences = getCookiePreferences();
    if (!savedPreferences) {
      setShowBanner(true);
    } else if (savedPreferences.version !== CONSENT_VERSION) {
      setShowBanner(true);
    } else {
      applyCookieSettings(savedPreferences.settings);
    }
  }, []);

  const acceptAll = () => {
    const allAccepted = {
      necessary: true,
      functional: true,
      analytics: true,
      marketing: true,
    };
    saveCookiePreferences(allAccepted, "banner");

    const bannerElement = document.querySelector('.cookie-banner-feedback');
    if (bannerElement) {
      bannerElement.textContent = "Preferences saved: All cookies accepted";
      setTimeout(() => {
        setShowBanner(false);
      }, 1000);
    } else {
      setShowBanner(false);
    }
  };

  const rejectAll = () => {
    saveCookiePreferences(defaultSettings, "banner");

    const bannerElement = document.querySelector('.cookie-banner-feedback');
    if (bannerElement) {
      bannerElement.textContent = "Preferences saved: Only necessary cookies accepted";
      setTimeout(() => {
        setShowBanner(false);
      }, 1000);
    } else {
      setShowBanner(false);
    }
  };

  const acceptNecessary = () => {
    saveCookiePreferences(defaultSettings, "banner");

    const bannerElement = document.querySelector('.cookie-banner-feedback');
    if (bannerElement) {
      bannerElement.textContent = "Preferences saved: Only necessary cookies accepted";
      setTimeout(() => {
        setShowBanner(false);
      }, 1000);
    } else {
      setShowBanner(false);
    }
  };

  const openDetailedSettings = () => {
    setShowBanner(false);
    setShowDetailedSettings(true);
  };

  const toggleLanguage = () => {
    setLanguage(language === "en" ? "de" : "en");
  };

  return (
    <>
      {showBanner && (
        <div className="fixed bottom-0 left-0 right-0 z-[10000] bg-white border-t border-gray-200 shadow-lg p-4 animate-fade-in-up">
          <div className="max-w-screen-lg mx-auto">
            <div className="flex flex-col gap-4">
              <div className="flex justify-between items-start">
                <div className="flex items-center">
                  <Cookie className="h-6 w-6 text-amber-600 mr-2" />
                  <h3 className="text-lg font-semibold">{t.title}</h3>
                </div>
                <Button
                  onClick={toggleLanguage}
                  variant="ghost"
                  size="sm"
                  className="ml-2 p-1"
                  title={language === "en" ? "Switch to German" : "Switch to English"}
                >
                  <Globe className="h-4 w-4 mr-1" />
                  <span>{language === "en" ? "DE" : "EN"}</span>
                </Button>
              </div>

              <p className="text-sm text-gray-600">
                {t.description}
              </p>

              <div className="cookie-banner-feedback text-center text-sm font-medium text-green-600 h-5"></div>

              <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                <Button variant="outline" onClick={rejectAll} className="bg-white hover:bg-gray-50">
                  <X className="h-4 w-4 mr-2" />
                  {language === "en" ? "Reject all" : "Alle ablehnen"}
                </Button>
                <Button variant="outline" onClick={openDetailedSettings} className="bg-white hover:bg-gray-50">
                  <Settings className="h-4 w-4 mr-2" />
                  {language === "en" ? "Customize" : "Anpassen"}
                </Button>
                <Button variant="outline" onClick={acceptNecessary} className="bg-white hover:bg-gray-50">
                  <Check className="h-4 w-4 mr-2" />
                  {language === "en" ? "Necessary only" : "Nur notwendige"}
                </Button>
                <Button onClick={acceptAll} className="bg-[#9b87f5] hover:bg-[#8a76e4] text-white">
                  <Check className="h-4 w-4 mr-2" />
                  {language === "en" ? "Accept all" : "Alle akzeptieren"}
                </Button>
              </div>

              <div className="text-xs text-gray-500 flex gap-4 justify-center">
                <Link to="/privacy-policy" className="hover:text-[#9b87f5] flex items-center">
                  <ExternalLink className="h-3 w-3 mr-1" />
                  {language === "en" ? "Privacy Policy" : "Datenschutz"}
                </Link>
                <Link to="/terms-of-service" className="hover:text-[#9b87f5] flex items-center">
                  <ExternalLink className="h-3 w-3 mr-1" />
                  {language === "en" ? "Terms of Service" : "Nutzungsbedingungen"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      <CookieSettingsDialog
        isOpen={showDetailedSettings}
        onOpenChange={setShowDetailedSettings}
      />
    </>
  );
};

interface CookieSettingsDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

const CookieSettingsDialog = ({ isOpen, onOpenChange }: CookieSettingsDialogProps) => {
  const [settings, setSettings] = useState<CookieSettings>(defaultSettings);
  const [activeTab, setActiveTab] = useState<string>("preferences");
  const { language, setLanguage } = useLanguage();

  const content = {
    en: {
      title: "Cookie Settings",
      description: "Customize your cookie preferences. Necessary cookies are always enabled as they are essential for the website to function properly.",
      tabs: {
        preferences: "Preferences",
        information: "Information",
        legal: "Legal"
      },
      necessaryCookies: "Necessary Cookies",
      necessaryDescription: "Required for the basic functionality of the website. The website cannot function properly without these cookies.",
      functionalCookies: "Functional Cookies",
      functionalDescription: "Enable enhanced functionality and personalization. Without these cookies, certain features may not be available.",
      analyticalCookies: "Analytical Cookies",
      analyticalDescription: "Help us understand how visitors interact with our website. This helps us improve our services and user experience.",
      marketingCookies: "Marketing Cookies",
      marketingDescription: "Used to track visitors across websites to display relevant advertisements. They help make advertising more effective and relevant to you.",
      cookieDetails: "Cookie Details",
      cookieName: "Cookie Name",
      cookiePurpose: "Purpose",
      cookieDuration: "Duration",
      cookieProvider: "Provider",
      legalTitle: "Legal Information",
      legalText: "According to the ePrivacy Directive and GDPR, we require your consent before storing cookies on your device, except for those cookies that are strictly necessary for the functionality of this website. Your consent is voluntary and can be withdrawn at any time. You can adjust your preferences by clicking on 'Cookie Settings' at the bottom of any page.",
      thirdPartyData: "Third-Party Data Transfers",
      thirdPartyText: "Some cookies may involve data transfers to countries outside the European Economic Area (EEA). By accepting these cookies, you consent to such transfers where appropriate safeguards have been implemented.",
      rightToWithdraw: "Right to Withdraw Consent",
      rightToWithdrawText: "You can withdraw your consent at any time by clearing your browser cookies and localStorage, or by using the Cookie Settings option available on our website.",
      cancel: "Cancel",
      save: "Save Settings",
      acceptAll: "Accept All",
      rejectAll: "Reject All",
      switchLanguage: "Switch language"
    },
    de: {
      title: "Cookie-Einstellungen",
      description: "Passen Sie Ihre Cookie-Einstellungen an. Notwendige Cookies sind immer aktiviert, da sie für die ordnungsgemäße Funktion der Website unerlässlich sind.",
      tabs: {
        preferences: "Einstellungen",
        information: "Informationen",
        legal: "Rechtliche"
      },
      necessaryCookies: "Notwendige Cookies",
      necessaryDescription: "Erforderlich für die grundlegende Funktionalität der Website. Die Website kann ohne diese Cookies nicht richtig funktionieren.",
      functionalCookies: "Funktionale Cookies",
      functionalDescription: "Ermöglichen erweiterte Funktionen und Personalisierung. Ohne diese Cookies sind bestimmte Funktionen möglicherweise nicht verfügbar.",
      analyticalCookies: "Analytische Cookies",
      analyticalDescription: "Helfen uns zu verstehen, wie Besucher mit unserer Website interagieren. Dies hilft uns, unsere Dienste und Benutzererfahrung zu verbessern.",
      marketingCookies: "Marketing-Cookies",
      marketingDescription: "Werden verwendet, um Besucher über Websites hinweg zu verfolgen und relevante Werbung anzuzeigen. Sie tragen dazu bei, dass Werbung effektiver und relevanter für Sie ist.",
      cookieDetails: "Cookie-Details",
      cookieName: "Cookie-Name",
      cookiePurpose: "Zweck",
      cookieDuration: "Dauer",
      cookieProvider: "Anbieter",
      legalTitle: "Rechtliche Informationen",
      legalText: "Gemäß der ePrivacy-Richtlinie und der DSGVO benötigen wir Ihre Einwilligung, bevor wir Cookies auf Ihrem Gerät speichern, mit Ausnahme jener Cookies, die für die Funktionalität dieser Website unbedingt erforderlich sind. Ihre Einwilligung ist freiwillig und kann jederzeit widerrufen werden. Sie können Ihre Präferenzen anpassen, indem Sie auf 'Cookie-Einstellungen' am unteren Rand jeder Seite klicken.",
      thirdPartyData: "Übermittlung von Daten an Drittländer",
      thirdPartyText: "Einige Cookies können Datenübertragungen in Länder außerhalb des Europäischen Wirtschaftsraums (EWR) beinhalten. Durch die Akzeptanz dieser Cookies stimmen Sie solchen Übertragungen zu, sofern geeignete Schutzmaßnahmen implementiert wurden.",
      rightToWithdraw: "Recht auf Widerruf der Einwilligung",
      rightToWithdrawText: "Sie können Ihre Einwilligung jederzeit widerrufen, indem Sie die Cookies und den localStorage Ihres Browsers löschen oder die Option 'Cookie-Einstellungen' auf unserer Website verwenden.",
      cancel: "Abbrechen",
      save: "Einstellungen speichern",
      acceptAll: "Alle akzeptieren",
      rejectAll: "Alle ablehnen",
      switchLanguage: "Sprache wechseln"
    }
  };

  const t = content[language];

  useEffect(() => {}, [language]);

  useEffect(() => {
    if (isOpen) {
      const savedPreferences = getCookiePreferences();
      if (savedPreferences) {
        setSettings(savedPreferences.settings);
      } else {
        setSettings(defaultSettings);
      }
    }
  }, [isOpen]);

  const handleSave = () => {
    saveCookiePreferences(settings, "detailed");

    const feedbackMessage = document.createElement('div');
    feedbackMessage.className = 'text-green-600 text-center py-2 font-medium';
    feedbackMessage.textContent = language === "en"
      ? "Your preferences have been saved"
      : "Ihre Einstellungen wurden gespeichert";

    const dialogContent = document.querySelector('.dialog-content');
    if (dialogContent) {
      dialogContent.appendChild(feedbackMessage);
      setTimeout(() => {
        onOpenChange(false);
      }, 1000);
    } else {
      onOpenChange(false);
    }
  };

  const handleAcceptAll = () => {
    const allAccepted = {
      necessary: true,
      functional: true,
      analytics: true,
      marketing: true,
    };
    saveCookiePreferences(allAccepted, "detailed");
    setSettings(allAccepted);

    const feedbackMessage = document.createElement('div');
    feedbackMessage.className = 'text-green-600 text-center py-2 font-medium';
    feedbackMessage.textContent = language === "en"
      ? "All cookies accepted"
      : "Alle Cookies akzeptiert";

    const dialogContent = document.querySelector('.dialog-content');
    if (dialogContent) {
      dialogContent.appendChild(feedbackMessage);
      setTimeout(() => {
        onOpenChange(false);
      }, 1000);
    } else {
      onOpenChange(false);
    }
  };

  const handleRejectAll = () => {
    saveCookiePreferences(defaultSettings, "detailed");
    setSettings({...defaultSettings});

    const feedbackMessage = document.createElement('div');
    feedbackMessage.className = 'text-green-600 text-center py-2 font-medium';
    feedbackMessage.textContent = language === "en"
      ? "Only necessary cookies accepted"
      : "Nur notwendige Cookies akzeptiert";

    const dialogContent = document.querySelector('.dialog-content');
    if (dialogContent) {
      dialogContent.appendChild(feedbackMessage);
      setTimeout(() => {
        onOpenChange(false);
      }, 1000);
    } else {
      onOpenChange(false);
    }
  };

  const handleChange = (key: keyof CookieSettings) => (value: boolean) => {
    if (key === 'necessary') return;
    setSettings(prev => ({ ...prev, [key]: value }));
  };

  const toggleLanguage = () => {
    setLanguage(language === "en" ? "de" : "en");
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[700px] max-h-[90vh] overflow-y-auto dialog-content">
        <DialogHeader>
          <DialogTitle className="flex items-center justify-between">
            <span>{t.title}</span>
            <Button
              onClick={toggleLanguage}
              variant="ghost"
              size="sm"
              className="ml-2 p-1"
              title={t.switchLanguage}
            >
              <Globe className="h-4 w-4 mr-1" />
              <span>{language === "en" ? "DE" : "EN"}</span>
            </Button>
          </DialogTitle>
          <DialogDescription>
            {t.description}
          </DialogDescription>
        </DialogHeader>

        <Tabs defaultValue="preferences" value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="grid grid-cols-3">
            <TabsTrigger value="preferences">{t.tabs.preferences}</TabsTrigger>
            <TabsTrigger value="information">{t.tabs.information}</TabsTrigger>
            <TabsTrigger value="legal">{t.tabs.legal}</TabsTrigger>
          </TabsList>

          <TabsContent value="preferences" className="py-4 space-y-6">
            <div className="flex items-center justify-between">
              <div className="space-y-0.5">
                <Label htmlFor="necessary" className="text-base font-medium">{t.necessaryCookies}</Label>
                <p className="text-sm text-gray-500">
                  {t.necessaryDescription}
                </p>
              </div>
              <Switch
                id="necessary"
                checked={settings.necessary}
                disabled={true}
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="space-y-0.5">
                <Label htmlFor="functional" className="text-base font-medium">{t.functionalCookies}</Label>
                <p className="text-sm text-gray-500">
                  {t.functionalDescription}
                </p>
              </div>
              <Switch
                id="functional"
                checked={settings.functional}
                onCheckedChange={(checked) => handleChange('functional')(checked)}
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="space-y-0.5">
                <Label htmlFor="analytics" className="text-base font-medium">{t.analyticalCookies}</Label>
                <p className="text-sm text-gray-500">
                  {t.analyticalDescription}
                </p>
              </div>
              <Switch
                id="analytics"
                checked={settings.analytics}
                onCheckedChange={(checked) => handleChange('analytics')(checked)}
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="space-y-0.5">
                <Label htmlFor="marketing" className="text-base font-medium">{t.marketingCookies}</Label>
                <p className="text-sm text-gray-500">
                  {t.marketingDescription}
                </p>
              </div>
              <Switch
                id="marketing"
                checked={settings.marketing}
                onCheckedChange={(checked) => handleChange('marketing')(checked)}
              />
            </div>
          </TabsContent>

          <TabsContent value="information" className="py-4">
            <h3 className="font-medium text-lg mb-4">{t.cookieDetails}</h3>

            <Accordion type="single" collapsible className="w-full">
              <AccordionItem value="necessary">
                <AccordionTrigger>
                  <div className="flex items-center">
                    <Info className="h-4 w-4 mr-2" />
                    {t.necessaryCookies}
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t.cookieName}</th>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t.cookiePurpose}</th>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t.cookieDuration}</th>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t.cookieProvider}</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {cookieDetails.necessary.map((cookie, index) => (
                          <tr key={index}>
                            <td className="px-2 py-3 text-xs">{cookie.name}</td>
                            <td className="px-2 py-3 text-xs">{cookie.purpose}</td>
                            <td className="px-2 py-3 text-xs">{cookie.duration}</td>
                            <td className="px-2 py-3 text-xs">{cookie.provider}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="functional">
                <AccordionTrigger>
                  <div className="flex items-center">
                    <Info className="h-4 w-4 mr-2" />
                    {t.functionalCookies}
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t.cookieName}</th>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t.cookiePurpose}</th>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t.cookieDuration}</th>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t.cookieProvider}</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {cookieDetails.functional.map((cookie, index) => (
                          <tr key={index}>
                            <td className="px-2 py-3 text-xs">{cookie.name}</td>
                            <td className="px-2 py-3 text-xs">{cookie.purpose}</td>
                            <td className="px-2 py-3 text-xs">{cookie.duration}</td>
                            <td className="px-2 py-3 text-xs">{cookie.provider}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="analytics">
                <AccordionTrigger>
                  <div className="flex items-center">
                    <Info className="h-4 w-4 mr-2" />
                    {t.analyticalCookies}
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t.cookieName}</th>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t.cookiePurpose}</th>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t.cookieDuration}</th>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t.cookieProvider}</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {cookieDetails.analytics.map((cookie, index) => (
                          <tr key={index}>
                            <td className="px-2 py-3 text-xs">{cookie.name}</td>
                            <td className="px-2 py-3 text-xs">{cookie.purpose}</td>
                            <td className="px-2 py-3 text-xs">{cookie.duration}</td>
                            <td className="px-2 py-3 text-xs">{cookie.provider}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="marketing">
                <AccordionTrigger>
                  <div className="flex items-center">
                    <Info className="h-4 w-4 mr-2" />
                    {t.marketingCookies}
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t.cookieName}</th>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t.cookiePurpose}</th>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t.cookieDuration}</th>
                          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t.cookieProvider}</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {cookieDetails.marketing.map((cookie, index) => (
                          <tr key={index}>
                            <td className="px-2 py-3 text-xs">{cookie.name}</td>
                            <td className="px-2 py-3 text-xs">{cookie.purpose}</td>
                            <td className="px-2 py-3 text-xs">{cookie.duration}</td>
                            <td className="px-2 py-3 text-xs">{cookie.provider}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </TabsContent>

          <TabsContent value="legal" className="py-4 space-y-6">
            <div>
              <h3 className="font-medium text-lg mb-2">{t.legalTitle}</h3>
              <p className="text-sm text-gray-600">
                {t.legalText}
              </p>
            </div>

            <div>
              <h3 className="font-medium text-lg mb-2">{t.thirdPartyData}</h3>
              <p className="text-sm text-gray-600">
                {t.thirdPartyText}
              </p>
            </div>

            <div>
              <h3 className="font-medium text-lg mb-2">{t.rightToWithdraw}</h3>
              <p className="text-sm text-gray-600">
                {t.rightToWithdrawText}
              </p>
            </div>
          </TabsContent>
        </Tabs>

        <div className="mb-4 text-sm text-gray-500 flex justify-center gap-6">
          <Link to="/privacy-policy" className="hover:text-[#9b87f5] flex items-center">
            <ExternalLink className="h-4 w-4 mr-1" />
            {language === "en" ? "Privacy Policy" : "Datenschutz"}
          </Link>
          <Link to="/terms-of-service" className="hover:text-[#9b87f5] flex items-center">
            <ExternalLink className="h-4 w-4 mr-1" />
            {language === "en" ? "Terms of Service" : "Nutzungsbedingungen"}
          </Link>
        </div>

        <DialogFooter className="flex-col sm:flex-row gap-2">
          <Button variant="outline" onClick={() => onOpenChange(false)} className="w-full sm:w-auto">
            {language === "en" ? "Cancel" : "Abbrechen"}
          </Button>
          <Button variant="outline" onClick={handleRejectAll} className="w-full sm:w-auto">
            {language === "en" ? "Reject All" : "Alle ablehnen"}
          </Button>
          <Button onClick={handleSave} className="w-full sm:w-auto">
            {language === "en" ? "Save Settings" : "Einstellungen speichern"}
          </Button>
          <Button onClick={handleAcceptAll} className="w-full sm:w-auto bg-[#9b87f5] hover:bg-[#8a76e4]">
            {language === "en" ? "Accept All" : "Alle akzeptieren"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const CookieSettingsButton = ({ compact = false }: { compact?: boolean }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { language } = useLanguage();

  const content = {
    en: {
      cookieSettings: "Cookie Settings",
    },
    de: {
      cookieSettings: "Cookie-Einstellungen",
    }
  };

  const t = content[language];

  if (compact) {
    return (
      <>
        <Button
          onClick={() => setIsOpen(true)}
          variant="outline"
          size="icon"
          className="rounded-full bg-amber-100 hover:bg-amber-200 border-amber-300 shadow-md w-12 h-12 transition-all hover:scale-105"
          title={t.cookieSettings}
        >
          <Cookie className="h-6 w-6 text-amber-800" />
        </Button>

        <CookieSettingsDialog isOpen={isOpen} onOpenChange={setIsOpen} />
      </>
    );
  }

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        variant="outline"
        size="sm"
        className="flex flex-col items-center gap-1 text-amber-800 hover:text-amber-900 transition-colors bg-amber-100 hover:bg-amber-200 border-amber-300 rounded-full px-4 py-2"
      >
        <Cookie className="h-6 w-6" />
        <span className="text-xs">{t.cookieSettings}</span>
      </Button>

      <CookieSettingsDialog isOpen={isOpen} onOpenChange={setIsOpen} />
    </>
  );
};
