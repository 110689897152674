
import { Link } from "react-router-dom";
import { useAuth } from "@/hooks/useAuth";

interface LogoProps {
  className?: string;
  size?: "sm" | "md" | "lg";
}

export const Logo = ({ className = "", size = "md" }: LogoProps) => {
  const { user } = useAuth();

  const sizeClasses = {
    sm: "h-12",
    md: "h-[50px]",
    lg: "h-18"
  };

  return (
    <Link
      to={user ? "/" : "/login"}
      className={`hover:opacity-80 transition-opacity ${className}`}
    >
      <img
        src="/logo.png"
        alt="BuyForS Logo"
        className={`${sizeClasses[size]} w-auto animate-fade-in`}
      />
    </Link>
  );
};
