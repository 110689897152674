
import { config } from "../config";
import { getBaseHeaders } from "./utils";
import { toast } from "sonner";

export const logout = async () => {
  try {
    const response = await fetch(`${config.API_URL}/user/logout`, {
      method: 'POST',
      headers: getBaseHeaders(),
      credentials: 'include',
    });

    const data = await response.json();

    if (!response.ok) {
      const errorMessage = data.msg || data.detail || data.message || data.error || response.statusText;
      toast.error(errorMessage);
      throw new Error(errorMessage);
    }

    const successMessage = data.msg || data.detail || data.message || 'Logged out successfully';
    toast.success(successMessage);

    window.location.reload();

    return true;
  } catch (error) {
    if (error instanceof Error) {
      toast.error(error.message);
    } else {
      toast.error('An unexpected error occurred during logout');
    }
    throw error;
  }
};
