import { config } from "../config";
import { getBaseHeaders, handleApiError } from "./utils";

export const getGoogleSSOLoginUrl = async () => {
  try {
    const response = await fetch(`${config.API_URL}/user/sso/google/login`, {
      method: 'GET',
      headers: getBaseHeaders(),
      credentials: 'include',
    });

    if (!response.ok) {
      await handleApiError(response, 'Get SSO login URL');
    }

    const data = await response.json();

    if (!data.redirect_url) {
      throw new Error('SSO redirect URL not found in response');
    }

    return data.redirect_url;
  } catch (error) {
    throw error;
  }
};
