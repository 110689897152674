declare global {
  interface Window {
    _env_: {
      VITE_API_URL: string;
      VITE_API_SECRET_TOKEN: string;
    }
  }
}

export const config = {
    API_URL: window._env_?.VITE_API_URL || import.meta.env.VITE_API_URL,
    API_SECRET_TOKEN: window._env_?.VITE_API_SECRET_TOKEN || import.meta.env.VITE_API_SECRET_TOKEN
};
