
import { config } from '../config';
import { getBaseHeaders } from '../auth/utils';

export interface Interest {
  id: number;
  user_id: number;
  category: InterestCategory;
  detail: string;
}

export type InterestCategory = 
  | "hobbies"
  | "sport"
  | "art"
  | "music"
  | "movie"
  | "book"
  | "tv_show"
  | "other";

export const interestsApi = {
  getInterests: async (userId: number): Promise<Interest[]> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/interests`, {
      headers: getBaseHeaders(),
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Failed to fetch interests');
    }

    return response.json();
  },

  createInterest: async (userId: number, data: { category: InterestCategory; detail: string }): Promise<Interest> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/interest`, {
      method: 'POST',
      headers: getBaseHeaders(),
      credentials: 'include',
      body: JSON.stringify({ user_id: userId, ...data }),
    });

    if (!response.ok) {
      throw new Error('Failed to create interest');
    }

    return response.json();
  },

  updateInterest: async (userId: number, interestId: number, data: { category: InterestCategory; detail: string }): Promise<Interest> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/interest/${interestId}`, {
      method: 'PUT',
      headers: getBaseHeaders(),
      credentials: 'include',
      body: JSON.stringify({ user_id: userId, ...data }),
    });

    if (!response.ok) {
      throw new Error('Failed to update interest');
    }

    return response.json();
  },

  deleteInterest: async (userId: number, interestId: number): Promise<void> => {
    const response = await fetch(`${config.API_URL}/user/${userId}/interest/${interestId}`, {
      method: 'DELETE',
      headers: getBaseHeaders(),
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Failed to delete interest');
    }
  },
};
